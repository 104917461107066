
<template>
  <div class="main-content">
    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <b-container>
      <b-card  title="Create Customer Segment" class="card-icon mb-30 p-16">
        <form action="#" class="form-horizontal "  method="post" v-on:submit.prevent="submitCustomerSegment">
          <div class="row">
            <div class="col-12">
              <app-timeline>
                <app-timeline-item variant="primary">
                  <div  class="mb-4 bg-primary p-1" >
                    <h5 class="text-white">1. Basic Information</h5>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label><strong>Segment Name: </strong></label> <br/>
                        <input type="text" class="form-control" v-model="segment.name" />
                      </div>
                    </div>
                  </div>
                </app-timeline-item>
                <app-timeline-item variant="secondary">
                  <div class="mb-4 bg-secondary p-1">
                    <h5 class="text-white">2. Rules </h5>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <table class="table table-bordered">
                        <tr v-for="rule_segment in segment.segments">
                          <td>
                            <select class="form-control" v-model="rule_segment.category">
                              <option value=""> -- Select Category -- </option>
                              <option :value="segment_category.value" v-for="segment_category in segment_categories"> {{ segment_category.key }}</option>
                            </select>
                          </td>
                          <td>
                            <div class="row" v-if="rule_segment.category == 'GENDER'">
                              <div class="col-6">
                                <div class="form-group">
                                  <label><strong>Gender: </strong></label> <br/>
                                  <select class="form-control" v-model="rule_segment.genderSegment.gender">
                                    <option :value="gender" v-for="gender in genders"> {{ gender }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="row" v-if="rule_segment.category == 'AGE'">
                              <div class="col-6">
                                <div class="form-group">
                                  <label><strong>Lower Limit: </strong></label> <br/>
                                  <input type="text" class="form-control" v-model="rule_segment.ageSegment.lowerBound" />
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-group">
                                  <label><strong>Upper Limit: </strong></label> <br/>
                                  <input type="text" class="form-control" v-model="rule_segment.ageSegment.upperBound" />
                                </div>
                              </div>
                            </div>
                            <div v-if="rule_segment.category == 'PAYMENT_MODE'">
                              <div class="row" >
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Date Category: </strong></label> <br/>
                                    <select class="form-control" v-model="rule_segment.paymentModeSegment.dateCategory">
                                      <option value="DURATION" > Duration</option>
                                      <option value="DATE_RANGE" > Date Range</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div v-if="rule_segment.paymentModeSegment.dateCategory == 'DURATION'" class="form-group">
                                    <label><strong>Duration in days (Last x days): </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.paymentModeSegment.duration" />
                                  </div>
                                  <div class="row" v-if="rule_segment.paymentModeSegment.dateCategory == 'DATE_RANGE'">
                                    <div class="col-6">
                                      <label><strong>Start Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.paymentModeSegment.startDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                    <div class="col-6">
                                      <label><strong>End Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.paymentModeSegment.endDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-6">
                                  <div  >
                                    <label class="form-label">
                                      Payment Modes:
                                    </label>
                                    <v-select
                                        label="name"
                                        v-model="segment.paymentModeSegment.paymentModes"
                                        dir="ltr"
                                        multiple
                                        :options="paymentModes"
                                    />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div  class="form-group">
                                    <label><strong>Frequency (No. of times the payment mode is used:) </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="segment.paymentModeSegment.frequency" />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="rule_segment.category == 'REGISTRATION'">
                              <div class="row" >
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Date Category: </strong></label> <br/>
                                    <select class="form-control" v-model="rule_segment.registrationSegment.dateCategory">
                                      <option value="DURATION" > Duration</option>
                                      <option value="DATE_RANGE" > Date Range</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div v-if="rule_segment.registrationSegment.dateCategory == 'DURATION'" class="form-group">
                                    <label><strong>Duration in days (Last x days): </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.registrationSegment.duration" />
                                  </div>
                                  <div class="row" v-if="rule_segment.registrationSegment.dateCategory == 'DATE_RANGE'">
                                    <div class="col-6">
                                      <label><strong>Start Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.paymentModeSegment.startDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                    <div class="col-6">
                                      <label><strong>End Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.paymentModeSegment.endDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="rule_segment.category == 'OUTLET'">
                              <div class="row" >
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Date Category: </strong></label> <br/>
                                    <select class="form-control" v-model="rule_segment.outletSegment.dateCategory">
                                      <option value="DURATION" > Duration</option>
                                      <option value="DATE_RANGE" > Date Range</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div v-if="rule_segment.outletSegment.dateCategory == 'DURATION'" class="form-group">
                                    <label><strong>Duration in days (Last x days): </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.outletSegment.duration" />
                                  </div>
                                  <div class="row" v-if="rule_segment.outletSegment.dateCategory == 'DATE_RANGE'">
                                    <div class="col-6">
                                      <label><strong>Start Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.outletSegment.startDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                    <div class="col-6">
                                      <label><strong>End Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.outletSegment.endDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <div  class="form-group">
                                    <label><strong>Frequency (No. of times outlet is visited:) </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="segment.outletSegment.frequency" />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div  >
                                    <label class="form-label">
                                      Outlet:
                                    </label>
                                    <v-select
                                        label="name"
                                        v-model="segment.outletSegment.outlets"
                                        dir="ltr"
                                        multiple
                                        :options="outlets"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-if="rule_segment.category == 'TRANSACTION_COST'">
                              <div class="row" >
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Date Category: </strong></label> <br/>
                                    <select class="form-control" v-model="rule_segment.transactionCostSegment.dateCategory">
                                      <option value="DURATION" > Duration</option>
                                      <option value="DATE_RANGE" > Date Range</option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div v-if="rule_segment.transactionCostSegment.dateCategory == 'DURATION'" class="form-group">
                                    <label><strong>Duration in days (Last x days): </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.transactionCostSegment.duration" />
                                  </div>
                                  <div class="row" v-if="rule_segment.transactionCostSegment.dateCategory == 'DATE_RANGE'">
                                    <div class="col-6">
                                      <label><strong>Start Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.transactionCostSegment.startDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                    <div class="col-6">
                                      <label><strong>End Date</strong></label> <br/>
                                      <date-picker style="width: 100%;"  v-model="rule_segment.transactionCostSegment.endDate" format="YYYY-MM-DD"   valueType="format" type="date"></date-picker>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row" >
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Lower Limit: </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.transactionCostSegment.lowerBound" />
                                  </div>
                                </div>
                                <div class="col-6">
                                  <div class="form-group">
                                    <label><strong>Upper Limit: </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.transactionCostSegment.upperBound" />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6">
                                  <div  class="form-group">
                                    <label><strong>Frequency (No. of times transacted:) </strong></label> <br/>
                                    <input type="text" class="form-control" v-model="rule_segment.transactionCostSegment.frequency" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <tr >
                            <td colspan="2">
                              <button type="button" @click="addRule()" class="btn btn-warning btn-sm float-right"> Add New </button>
                            </td>
                          </tr>
                        </tr>

                      </table>
                    </div>
                  </div>

                </app-timeline-item>
              </app-timeline>
            </div>
            <div class="col-12 mt-3 mb-3">
              <hr/>
            </div>
            <div class="col-12">
              <div style="clear: both;">
                <button class="btn btn-success float-right"  type="submit" >Submit</button>
              </div>
            </div>
          </div>
        </form>
      </b-card>
    </b-container>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css'
  import Multiselect from 'vue-multiselect';
  import vSelect from 'vue-select'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import Button from "@/views/components/button/Button.vue";
  import Table from "@/views/table/bs-table/Table.vue";

export default {
  data() {
    return {
      segment_categories:[
        {
          key: "Gender",
          value:"GENDER"
        },
        {
          key: "Age",
          value:"AGE"
        },
        {
          key: "Payment Mode",
          value:"PAYMENT_MODE"
        },
        {
          key: "Registration",
          value:"REGISTRATION"
        },
        {
          key: "Outlet",
          value:"OUTLET"
        },
        {
          key: "Transaction Cost",
          value:"TRANSACTION_COST"
        }
      ],
      outletProduct:"",
      selectMode: 'multi',
      days:["MON","TUE","WED","THU","FRI","SAT","SUN"],
      disabled:false,
      isLoading: false,
      fullPage: true,
      outlets:[],
      partners:[],
      products:[],
      outlet_columns:["name"],
      genders:["MALE","FEMALE"],
      segmentCategories:["AGE","GENDER","REGISTRATION","OUTLET","TRANSACTION_COST","PAYMENT_MODE"],
      segment:{
        name: "",
        segments: [],
        ageSegment: {
          lowerBound: "",
          upperBound: ""
        },
        genderSegment: {
          gender: ""
        },
        paymentModeSegment: {
          dateCategory: "",
          duration: "",
          paymentModes: [],
          frequency: "",
          startDate:"",
          endDate:""
        },
        transactionCostSegment: {
          dateCategory: "",
          startDate: "",
          endDate: "",
          lowerBound: "",
          upperBound: "",
          frequency: ""
        },
        registrationSegment: {
          dateCategory: "",
          duration: ""
        },
        outletSegment: {
          dateCategory: "",
          duration: "",
          frequency: "",
          outlets: []
        },
        productSegment: {
          dateCategory: "",
          startDate: "",
          endDate: "",
          frequency: "",
          products: []
        }
      },
      selectedProducts:[],
      selectedPaymentModes:[],
      paymentModes:[]
    };
  },
  components: {
    Table,
    Button,
    AppTimeline,
    AppTimelineItem,
    Loading,
    DatePicker,
    Multiselect,
    vSelect
  },

  mounted (){
    this.getOutletsData();
    this.getPaymentModesData();
  },
  computed:{

  },
  methods: {
    ...mapActions(["createCustomerSegment","getOutlets","getPaymentModes"]),
    addRule(){
      let segment = {
        id: 0,
        category:"",
        ageSegment: {
          lowerBound: "",
          upperBound: ""
        },
        genderSegment: {
          gender: ""
        },
        paymentModeSegment: {
          dateCategory: "",
          duration: "",
          paymentModes: [],
          frequency: "",
          startDate:"",
          endDate:""
        },
        transactionCostSegment: {
          dateCategory: "",
          startDate: "",
          endDate: "",
          lowerBound: "",
          upperBound: "",
          frequency: ""
        },
        registrationSegment: {
          dateCategory: "",
          duration: ""
        },
        outletSegment: {
          dateCategory: "",
          duration: "",
          frequency: "",
          outlets: []
        },
        productSegment: {
          dateCategory: "",
          startDate: "",
          endDate: "",
          frequency: "",
          products: []
        }
      }
      this.segment.segments.push(segment);
    },
    getOutletsData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {
          self.outlets = outlets;
        })
        .catch(function (error) {

        })
    },
    submitCustomerSegment() {
      let self = this;

      this.isLoading = true;
      this.segment.segments.forEach(segment => {
        if(segment.category == 'PAYMENT_MODE') {
          segment.paymentModeSegment.paymentModes = segment.paymentModeSegment.paymentModes.map(function (paymentMode) {
            return paymentMode.id
          })
        } else if(segment.category == 'OUTLET') {
          segment.outletSegment.outlets = segment.outletSegment.outlets.map(function (outlet) {
            return outlet.id
          })
        }
      });

      console.log( this.segment)

      this.createCustomerSegment(this.segment).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Segment saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        this.$router.push("/apps/segments");

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    getPaymentModesData() {
      let self = this;
      this.getPaymentModes()
          .then(function (paymentModes) {
            self.paymentModes = paymentModes;
          })
          .catch(function (error) {

          })
    }
  },

};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.rule-wrapper {
  position: relative;
  width: 136px;
  height: 141px;
  margin: 10px;
}

.rule-line {
  position: absolute;
  left: 49%;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #ccc;
  z-index: 1;
}

.rule-wordwrapper {
  text-align: center;
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: -12px;
  z-index: 2;
}

.rule-word {
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 3px;
  font: bold 12px arial,sans-serif;
  background: #fff;
}

</style>
